let MAX_GRID_ITEMS = 5
let ITEMS_PER_ROW = 5
// Wird über Lazy Load Script geladen
// document.addEventListener('DOMContentLoaded', function () {
randomizeFirstRow()
loadMoreProduct()
// })

function randomizeFirstRow() {
  // Get all Modules to be randomized
  const productGrids = document.querySelectorAll(
    '.aw_product_grid.aw_randomized'
  )

  productGrids.forEach((productGrid) => {
    // Get all visible Product Item
    let productsObject = productGrid.querySelectorAll('.aw_product_item')
    randomizeProducts(productsObject, productGrid)
  })
}

function randomizeProducts(productsObject, productGrid) {
  // Convert Node list to array to randomize it
  let productsArray = Array.from(productsObject)

  const randomizedArray = shuffleFisherYates(productsArray)
  // Clear old Product order in Dom
  productsObject.forEach((productItem) => {
    productItem.remove()
  })

  ITEMS_PER_ROW = +productGrid.dataset.itemsPerRow
  // Set up new product order in Dom
  for (let i = 0; i < randomizedArray.length; i++) {
    randomizedArray[i].classList.remove('aw_hidden_product')

    if (i >= ITEMS_PER_ROW) {
      randomizedArray[i].classList.add('aw_hidden_product')
    } else {
      const firstImage = randomizedArray[i].querySelector('img.aw_first_image')
      if (!firstImage.src) {
        firstImage.srcset = firstImage.dataset.srcset
        firstImage.src = firstImage.dataset.src
      }

      const hoverImage = randomizedArray[i].querySelector('img.aw_hover_image')

      if (hoverImage && !hoverImage.src) {
        hoverImage.srcset = hoverImage.dataset.srcset
        hoverImage.src = hoverImage.dataset.src
      }
    }
    productGrid.appendChild(randomizedArray[i])
  }
}

function loadMoreProduct() {
  const loadMoreBtns = document.querySelectorAll('.aw_load_more_btn')

  loadMoreBtns.forEach((btn) => {
    btn.addEventListener('click', (e) => {
      const moduleId = e.target.dataset.moduleId

      //Select hidden Product for Rondomizer Function
      let hiddenProducts = document.querySelectorAll(
        `#abw_product_grid_${moduleId} .aw_hidden_product`
      )

      // Bail if there arn't any hidden products
      if (!hiddenProducts || e.target.dataset.btnAllActivated) return

      // hiddenProducts.dataset.btnAllActivated = true

      e.target.innerText = e.target.dataset.labelAll
      e.target.dataset.btnAllActivated = 'true'
      MAX_GRID_ITEMS = +e.target.dataset.maxGridItems

      if (MAX_GRID_ITEMS < ITEMS_PER_ROW) MAX_GRID_ITEMS = ITEMS_PER_ROW

      e.target.blur()
      e.preventDefault()

      // Select again all hidden products, because they are now rondomized
      hiddenProducts = document.querySelectorAll(
        `#abw_product_grid_${moduleId} .aw_hidden_product`
      )

      for (let i = 0; i < MAX_GRID_ITEMS - ITEMS_PER_ROW + 1; i++) {
        if (!hiddenProducts[i]) continue
        // console.log(hiddenProducts)
        const productImages = hiddenProducts[i].querySelectorAll('img')

        productImages.forEach((productImage) => {
          if (!productImage.src) {
            productImage.srcset = productImage.dataset.srcset
            productImage.src = productImage.dataset.src
          }
        })

        hiddenProducts[i].classList.remove('aw_hidden_product')
      }
    })
  })

  // console.log(MAX_GRID_ITEMS, ITEMS_PER_ROW)
}

function shuffleFisherYates(array) {
  let i = array.length

  while (i--) {
    const ri = Math.floor(Math.random() * i)
    ;[array[i], array[ri]] = [array[ri], array[i]]
  }

  return array
}
